<template>
  <div class="travelSpecsList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>套餐列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>套餐名称:</span>
      <el-input
        v-model="list.specsName"
        placeholder="请输入套餐名称"
      ></el-input>
      <span>状态:</span>
      <el-select v-model="list.status" placeholder="请选择状态值">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button type="primary" @click="getTravelSpecsList()">查找</el-button>
      <el-button
        type="primary"
        @click="navTo(`/addTravelSpecs/${id}/0/${sellWay}`)"
        v-if="$store.state.powerList.indexOf('travel:specs:list:add') !== -1"
        >添加</el-button
      >
      <el-button type="primary" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-table
      @selection-change="onSelectionChange"
      :data="tableData"
      border
      style="width: 100%"
    >
      <el-table-column align="center" type="selection"></el-table-column>
      <el-table-column align="center" prop="dept" label="排序">
        <template slot-scope="{ row }">
          <el-input
            class="input-new-tag"
            v-if="row.inputVisible"
            v-model="row.dept"
            ref="saveTagInput"
            size="small"
            @input="onInput"
            @keyup.enter.native="handleInputConfirm(row)"
            @blur="handleInputConfirm(row)"
          >
          </el-input>
          <el-button v-else class="button-new-tag" @click="onChangeSort(row)">{{
            row.dept
          }}</el-button>
        </template></el-table-column
      >
      <el-table-column
        align="center"
        prop="specsId"
        label="套餐ID"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="travelName"
        label="线路名称"
        width="300"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="specsName"
        label="套餐名称"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="roomDays"
        label="入住天数"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="roomCount"
        label="所需房间数量"
      ></el-table-column>

      <el-table-column
        align="center"
        prop="strStatus"
        label="状态"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="strSellWay"
        label="销售方式"
      ></el-table-column>
      <el-table-column align="center" label="操作" width="350">
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            type="primary"
            @click="onChangeStatus(row, 1)"
            v-show="row.strStatus == '下架'"
            v-if="
              $store.state.powerList.indexOf('travel:specs:list:update') !== -1
            "
            >上架</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="onChangeStatus(row, 2)"
            v-show="row.strStatus == '上架'"
            v-if="
              $store.state.powerList.indexOf('travel:specs:list:update') !== -1
            "
            >下架</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="navTo(`/addTravelSpecs/${id}/${row.specsId}/${sellWay}`)"
            v-if="
              $store.state.powerList.indexOf('travel:specs:list:edit') !== -1
            "
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="navTo(`/travelSpecsPrice/${row.specsId}`)"
            v-if="
              $store.state.powerList.indexOf('travel:specs:list:price') !== -1
            "
            >设置价格</el-button
          >
          <el-dropdown>
            <el-button type="primary" size="mini" style="margin-left: 10px">
              更多操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-button
                  size="mini"
                  type="primary"
                  @click="onChangeStatus(row, 0)"
                  v-if="
                    $store.state.powerList.indexOf(
                      'travel:specs:list:update'
                    ) !== -1
                  "
                  style="width: 75px"
                  >删除</el-button
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  size="mini"
                  type="primary"
                  @click="navTo(`/travelSpecsLog/${row.specsId}`)"
                  v-if="
                    $store.state.powerList.indexOf('travel:specs:list:add') !==
                    -1
                  "
                  >查看日志</el-button
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  type="primary"
                  size="mini"
                  style="width: 75px"
                  v-if="
                    $store.state.powerList.indexOf('travel:specs:list:add') !==
                    -1
                  "
                  @click="addByCodySpecs(row.specsId)"
                  >一键复制</el-button
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  type="primary"
                  size="mini"
                  style="width: 75px"
                  @click="onGOGroupBuy(row.specsId)"
                  >去拼团</el-button
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  type="primary"
                  size="mini"
                  style="width: 75px"
                  @click="onGOSecKill(row.specsId)"
                  >去秒杀</el-button
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <div class="btn">
      <el-button
        type="primary"
        @click="onChangeAllStauts(1)"
        v-if="$store.state.powerList.indexOf('travel:specs:list:update') !== -1"
        >批量上架</el-button
      >
      <el-button
        type="primary"
        @click="onChangeAllStauts(2)"
        v-if="$store.state.powerList.indexOf('travel:specs:list:update') !== -1"
        >批量下架</el-button
      >
      <el-button
        type="primary"
        @click="onChangeAllStauts(0)"
        v-if="$store.state.powerList.indexOf('travel:specs:list:update') !== -1"
        >批量删除</el-button
      >
    </div>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>
<script>
import {
  travelSpecsList,
  changeSort,
  changeStatus,
  addByCody,
} from "../../api/travelSpecs";
import { isActivity } from "../../api/groupBuy";
import { isActivityKill } from "../../api/seckillInfo";
export default {
  name: "travelSpecsList",
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      ids: "",
      tableData: [],
      options: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "上架",
        },
        {
          value: 2,
          label: "下架",
        },
      ],
      list: {
        currentPage: 1,
        pageSize: 5,
        travelId: Number(this.id),
        specsName: "",
        status: null,
      },
      pagination: {},
      sellWay: null, //个人  套餐
    };
  },
  created() {
    this.navTo();
    this.getTravelSpecsList();
  },
  methods: {
    navTo(url) {
      if (url) {
        sessionStorage.setItem(
          "WANLV_TRAVEL_SPECS_LIST_PAGE",
          this.list.currentPage
        );
        this.$router.push(url);
      } else {
        this.list.currentPage =
          sessionStorage.getItem("WANLV_TRAVEL_SPECS_LIST_PAGE") || 1;
        sessionStorage.setItem("WANLV_TRAVEL_SPECS_LIST_PAGE", 1);
      }
    },
    async onGOSecKill(specsId) {
      const { data } = await isActivityKill({
        prodType: 3,
        prodId: specsId,
      });
      if (data.code == 0) {
        this.$message.success(data.msg);
        this.$router.push(`/addSeckill/0/${specsId}/${3}`);
      } else {
        this.$message.error(data.msg);
      }
    },
    async onGOGroupBuy(specsId) {
      const { data } = await isActivity({
        prodType: 3,
        prodId: specsId,
      });
      if (data.code == 0) {
        this.$message.success(data.msg);
        this.$router.push(`/addGroupBuy/0/${specsId}/${3}`);
      } else {
        this.$message.error(data.msg);
      }
    },
    addByCodySpecs(specsId) {
      this.$confirm("确定一键复制这条收据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await addByCody({
            specsId,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getTravelSpecsList();
          } else {
            this.$message.error(data.msg);
          }
        })
        .catch(() => {});
    },
    onInput(value) {
      var reg = /^[0-9]*[1-9][0-9]*$/;
      var reg2 = /^-[0-9]*[1-9][0-9]*$/;
      if (!reg.test(value) && !reg2.test(value)) {
        return this.$message({
          type: "warning",
          message: "请输入整数!",
        });
      }
    },
    async getTravelSpecsList() {
      const { data } = await travelSpecsList(this.list);
      data.list.map((item) => {
        item.inputVisible = false;
      });
      this.sellWay = data.msg;
      this.tableData = data.list;
      this.pagination = data.pagination;
    },
    async handleInputConfirm(row) {
      const { data } = await changeSort({
        specsId: row.specsId,
        dept: row.dept,
      });
      console.log(data);
      row.inputVisible = false;
    },
    onChangeStatus(row, status) {
      this.$confirm("是否改变这条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await changeStatus({
            ids: row.specsId + "",
            status,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: "成功!",
            });
            this.getTravelSpecsList();
          } else {
            this.$message({
              type: "error",
              message: "失败!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    onChangeSort(row) {
      row.inputVisible = true;
    },
    onChangeAllStauts(status) {
      this.$confirm("是否批量改变这条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          if (this.ids === "") {
            this.$message({
              type: "error",
              message: "请选择数据!",
            });
            return;
          }
          const { data } = await changeStatus({
            ids: this.ids,
            status,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: "成功!",
            });
            this.getTravelSpecsList();
          } else {
            this.$message({
              type: "error",
              message: "失败!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    onSelectionChange(selection) {
      var arr = [];
      selection.map((item) => {
        arr.push(item.specsId);
      });
      this.ids = arr.join(",");
    },
    handleSizeChange(size) {
      this.list.pageSize = size;
      this.getTravelSpecsList();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.getTravelSpecsList();
    },
  },
};
</script>
<style lang="less" scoped>
.travelSpecsList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top: 50px;
    span {
      margin: 0 20px 0 40px;
    }
    .el-button {
      margin-left: 30px;
    }
    .el-input {
      width: 400px;
    }
  }
  .el-table {
    margin-top: 50px;
  }
  .btn {
    margin-top: 50px;
    float: left;
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
}
</style>