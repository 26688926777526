var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"travelSpecsList"},[_c('el-breadcrumb',{attrs:{"separator":"/"}},[_c('el-breadcrumb-item',[_vm._v("套餐列表")])],1),_c('div',{staticClass:"search"},[_c('span',[_vm._v("套餐名称:")]),_c('el-input',{attrs:{"placeholder":"请输入套餐名称"},model:{value:(_vm.list.specsName),callback:function ($$v) {_vm.$set(_vm.list, "specsName", $$v)},expression:"list.specsName"}}),_c('span',[_vm._v("状态:")]),_c('el-select',{attrs:{"placeholder":"请选择状态值"},model:{value:(_vm.list.status),callback:function ($$v) {_vm.$set(_vm.list, "status", $$v)},expression:"list.status"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.getTravelSpecsList()}}},[_vm._v("查找")]),(_vm.$store.state.powerList.indexOf('travel:specs:list:add') !== -1)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.navTo(("/addTravelSpecs/" + _vm.id + "/0/" + _vm.sellWay))}}},[_vm._v("添加")]):_vm._e(),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("返回")])],1),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":""},on:{"selection-change":_vm.onSelectionChange}},[_c('el-table-column',{attrs:{"align":"center","type":"selection"}}),_c('el-table-column',{attrs:{"align":"center","prop":"dept","label":"排序"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.inputVisible)?_c('el-input',{ref:"saveTagInput",staticClass:"input-new-tag",attrs:{"size":"small"},on:{"input":_vm.onInput,"blur":function($event){return _vm.handleInputConfirm(row)}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleInputConfirm(row)}},model:{value:(row.dept),callback:function ($$v) {_vm.$set(row, "dept", $$v)},expression:"row.dept"}}):_c('el-button',{staticClass:"button-new-tag",on:{"click":function($event){return _vm.onChangeSort(row)}}},[_vm._v(_vm._s(row.dept))])]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"specsId","label":"套餐ID"}}),_c('el-table-column',{attrs:{"align":"center","prop":"travelName","label":"线路名称","width":"300"}}),_c('el-table-column',{attrs:{"align":"center","prop":"specsName","label":"套餐名称"}}),_c('el-table-column',{attrs:{"align":"center","prop":"roomDays","label":"入住天数"}}),_c('el-table-column',{attrs:{"align":"center","prop":"roomCount","label":"所需房间数量"}}),_c('el-table-column',{attrs:{"align":"center","prop":"strStatus","label":"状态"}}),_c('el-table-column',{attrs:{"align":"center","prop":"strSellWay","label":"销售方式"}}),_c('el-table-column',{attrs:{"align":"center","label":"操作","width":"350"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(
            _vm.$store.state.powerList.indexOf('travel:specs:list:update') !== -1
          )?_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(row.strStatus == '下架'),expression:"row.strStatus == '下架'"}],attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.onChangeStatus(row, 1)}}},[_vm._v("上架")]):_vm._e(),(
            _vm.$store.state.powerList.indexOf('travel:specs:list:update') !== -1
          )?_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(row.strStatus == '上架'),expression:"row.strStatus == '上架'"}],attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.onChangeStatus(row, 2)}}},[_vm._v("下架")]):_vm._e(),(
            _vm.$store.state.powerList.indexOf('travel:specs:list:edit') !== -1
          )?_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.navTo(("/addTravelSpecs/" + _vm.id + "/" + (row.specsId) + "/" + _vm.sellWay))}}},[_vm._v("编辑")]):_vm._e(),(
            _vm.$store.state.powerList.indexOf('travel:specs:list:price') !== -1
          )?_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.navTo(("/travelSpecsPrice/" + (row.specsId)))}}},[_vm._v("设置价格")]):_vm._e(),_c('el-dropdown',[_c('el-button',{staticStyle:{"margin-left":"10px"},attrs:{"type":"primary","size":"mini"}},[_vm._v(" 更多操作"),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',[(
                  _vm.$store.state.powerList.indexOf(
                    'travel:specs:list:update'
                  ) !== -1
                )?_c('el-button',{staticStyle:{"width":"75px"},attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.onChangeStatus(row, 0)}}},[_vm._v("删除")]):_vm._e()],1),_c('el-dropdown-item',[(
                  _vm.$store.state.powerList.indexOf('travel:specs:list:add') !==
                  -1
                )?_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.navTo(("/travelSpecsLog/" + (row.specsId)))}}},[_vm._v("查看日志")]):_vm._e()],1),_c('el-dropdown-item',[(
                  _vm.$store.state.powerList.indexOf('travel:specs:list:add') !==
                  -1
                )?_c('el-button',{staticStyle:{"width":"75px"},attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.addByCodySpecs(row.specsId)}}},[_vm._v("一键复制")]):_vm._e()],1),_c('el-dropdown-item',[_c('el-button',{staticStyle:{"width":"75px"},attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.onGOGroupBuy(row.specsId)}}},[_vm._v("去拼团")])],1),_c('el-dropdown-item',[_c('el-button',{staticStyle:{"width":"75px"},attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.onGOSecKill(row.specsId)}}},[_vm._v("去秒杀")])],1)],1)],1)]}}])})],1),_c('div',{staticClass:"btn"},[(_vm.$store.state.powerList.indexOf('travel:specs:list:update') !== -1)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.onChangeAllStauts(1)}}},[_vm._v("批量上架")]):_vm._e(),(_vm.$store.state.powerList.indexOf('travel:specs:list:update') !== -1)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.onChangeAllStauts(2)}}},[_vm._v("批量下架")]):_vm._e(),(_vm.$store.state.powerList.indexOf('travel:specs:list:update') !== -1)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.onChangeAllStauts(0)}}},[_vm._v("批量删除")]):_vm._e()],1),_c('el-pagination',{attrs:{"current-page":_vm.list.currentPage,"page-sizes":[5, 10, 15, 20],"page-size":_vm.list.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.pagination.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }